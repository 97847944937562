import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import useContactDetails from '../contactDetails';
import useSimpleHeader from '../simpleHeader';

export const ExperimentContext = createContext({});

const ExperimentProvider = ({ children }) => {
    const [isContactDetailsTest, setContactDetailsTest] = useState(useContactDetails());
    const isSimpleHeaderTest = useSimpleHeader();
    const experiments = {
        isContactDetailsTest,
        setContactDetailsTest,
        isSimpleHeaderTest
    };
    return <ExperimentContext.Provider value={experiments}>{children}</ExperimentContext.Provider>;
};

export const useExperiments = () => useContext(ExperimentContext);

export default ExperimentProvider;

ExperimentProvider.propTypes = {
    children: PropTypes.node.isRequired
};
